import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "page-navigation" }
const _hoisted_2 = { class: "main-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("nav", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (menuItem) => {
        return (_openBlock(), _createBlock(_component_router_link, {
          key: menuItem.name,
          to: menuItem.url,
          class: "link",
          "active-class": "active"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(menuItem.name), 1)
          ]),
          _: 2
        }, 1032, ["to"]))
      }), 128))
    ]),
    _createElementVNode("main", _hoisted_2, [
      _createVNode(_component_router_view)
    ]),
    _createVNode(_component_Footer)
  ], 64))
}

import { defineComponent } from 'vue';

import Footer from '@/components/Footer.vue';

interface LinkItem {
  url: string;
  name: string;
}

export default defineComponent({
  components: { Footer },
  setup() {
    const menuItems: LinkItem[] = [
      {
        url: '/',
        name: 'About',
      },
      {
        url: '/videos',
        name: 'Videos',
      },
    ];

    return { menuItems };
  },
});
